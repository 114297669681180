import React, { useState } from "react";
import { Image, Link } from "react-feather";
import {
  DrawerBody,
  DrawerFooter,
  DrawerSection,
  DrawerSectionBody,
  DrawerSectionFrame,
  DrawerSeparator,
  DrawerTitle,
} from ".";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n from "../../hooks/useI18n";
import { useLoading } from "../../hooks/useLoading";
import { Page, addVersion } from "../../model";
import appTexts from "../../texts";
import * as actions from "../../store/actions";
import {
  DrawerState,
  useAppDrawer,
  useDrawerBackdrop,
} from "../../hooks/useAppDrawer";
import * as toasts from "../../toasts";
import { createPortal } from "react-dom";
import { UploadBlock, UploadImage } from "./ProjectDesignDrawer";
import theme from "../../theme";
import DatePicker from "react-datepicker";
import SimpleMDE from "react-simplemde-editor";
import { Stat } from "../Stat";
import { api } from "../../api";
import { usePromise } from "../../hooks/usePromise";
import _ from "lodash";
import styled from "styled-components";
import { Source, State } from "../../store/types";
import { getPageScreenshot, getPageScreenshots } from "../../model";
import texts from "../../texts";
import SourceInput from "../ui/SourceInput";

/**
 * Drawer to display clip details.
 */
export default function ClipDrawer({ id }: { id: string }) {
  useDrawerBackdrop();
  const page = useAppState((s) => s.pages.find((p) => p.id === id));
  // Select drawer content based on page state
  const clipState = getClipState(page);
  if (clipState === "loading" || !page) {
    return <DrawerBody></DrawerBody>;
  }
  if (clipState === "error") {
    return <ErrorContent page={page} />;
  }
  if (clipState === "crawling") {
    return <CrawlingContent page={page} />;
  }
  return <EditClipContent page={page} />;
}

function getClipState(
  page?: Page | null
): "loading" | "error" | "crawling" | "ready" {
  if (!page) {
    return "loading";
  } else if (page?.error) {
    return "error";
  } else if (page?.url && !page?.crawled) {
    return "crawling";
  } else {
    return "ready";
  }
}

/**
 * Displayed after crawling is done, user can edit its clip.
 */
function EditClipContent({ page }: { page: Page }) {
  const t = useI18n();
  const close = useAppDrawer((s: DrawerState) => s.close);
  const withCfc = useAppState(State.withCfc);
  const team = useAppState(State.getTeam);
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const [kind, setKind] = useState(page.user_kind || page.kind);
  const [image, setImage] = useState<string | null>(getPageScreenshot(page));
  const [screenshots, setScreenshots] = useState(getPageScreenshots(page));
  const [date, setDate] = useState(
    page.published_time ? new Date(page.published_time) : null
  );
  const [title, setTitle] = useState(page.title);
  const [desc, setDesc] = useState(page.description);
  const [stats, setStats] = useState(_.cloneDeep(page.stats));
  const [source_id, setSourceId] = useState(page.source_id);
  const [source, setSource] = useState<Source | null | { create: string }>(
    page.source || null
  );
  const [cfcSource, setCfcSource] = useState(
    source && "id" in source && source.cfc
  );
  // find if there was a modification
  const hasModification = !_.isEqual(
    {
      kind: page.user_kind || page.kind,
      image: getPageScreenshot(page),
      date: page.published_time || null,
      title: page.title,
      desc: page.description,
      stats: page.stats,
      source_id: page.source_id,
      screenshots: getPageScreenshots(page).join("--"),
      sourceToCreate: false,
      cfcSource: page.source?.cfc || false,
    },
    {
      kind,
      image,
      date: date ? date.toISOString() : null,
      title,
      desc,
      stats,
      source_id,
      screenshots: screenshots.join("--"),
      sourceToCreate: source && "create" in source,
      cfcSource,
    }
  );
  // Update local stats data when user changes a stat input
  const handleStatChange = (
    changes: [string, boolean, number | null | undefined][]
  ) => {
    let result = _.cloneDeep(stats);
    changes.forEach(([key, disabled, value]) => {
      const exists = result.find((s) => s.key === key);
      if (exists) {
        result = result.map((s) => {
          if (s.key === key) return { ...s, disabled, userValue: value };
          return s;
        });
      } else {
        result = result.concat([
          {
            key,
            disabled,
            userValue: value,
            udate: new Date().toISOString(),
          },
        ]);
      }
    });
    setStats(result);
  };
  // API defined stats
  const allStats = usePromise(async () =>
    api.GetStatsList({}, { v: 2 }).then((res) => res.data)
  );
  // Compute stats display
  const displayedStats =
    allStats.data &&
    allStats.data
      .filter(
        (row) =>
          row["for"] == null ||
          row["for"].includes(page.kind) ||
          row["for"].includes(page.user_kind || "--")
      )
      .map(({ group, stats: groupStats }) => ({
        group,
        stats: groupStats.map((s) => ({
          ...s,
          value: stats.find((ps) => ps.key === s.key),
        })),
      }));
  // Trigger refresh clip request
  const handleRefresh = (e: React.MouseEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    load(dispatch(actions.pages.crawlPage(page.id)));
  };
  // Delete clip
  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    dispatch(actions.pages.deletePage(page.id))
      .then(close)
      .then(() => toasts.success("Clip deleted."));
  };
  // Save modifications
  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    load(async () => {
      // create source if needed
      let newSource = source && "id" in source ? source : null;
      let newSourceId = source_id;
      if (source && "create" in source && team) {
        const res = await api.CreateSource(
          {},
          {
            name: source.create,
            kind: "offline",
            readership:
              stats.find((s) => s.key === "offline_users")?.userValue || 0,
            views: stats.find((s) => s.key === "offline_views")?.userValue || 0,
            team_id: team.id,
            cfc: !!cfcSource,
          }
        );
        newSourceId = res.data.id;
        newSource = res.data;
        setSource(res.data);
        setSourceId(res.data.id);
      }
      // save stats if needed
      for (let stat of stats) {
        const origin = page.stats.find((s) => s.key === stat.key);
        if (!origin || !_.isEqual(stat, origin)) {
          await api.UpdatePageStat(
            { pageId: page.id, statId: stat.key },
            {
              key: stat.key,
              disabled: stat.disabled,
              value: stat.userValue,
            }
          );
        }
      }
      if (newSource && newSource.cfc !== cfcSource && team) {
        await api.UpdateSource(
          { id: newSource.id },
          {
            name: newSource.name,
            kind: newSource.kind,
            readership: newSource.readership,
            views: newSource.views,
            team_id: team.id,
            cfc: !!cfcSource,
          }
        );
      }
      // dispatch page update
      await dispatch(
        actions.pages.updatePage(page.id, {
          kind,
          title,
          publication_name: newSource?.name || "",
          screenshot: image,
          screenshots,
          description: desc,
          published_time: date ? date.toISOString() : undefined,
          source_id: newSourceId,
        })
      );
      // notify
      toasts.saved();
    });
  };
  return (
    <>
      <DrawerBody>
        <DrawerTitle
          title={
            page.kind === "custom"
              ? t({ en: <>Print coverage</>, fr: <>Couverture papier</> })
              : t({
                  en: <>Customize clip</>,
                  fr: <>Personnalisez la coupure</>,
                })
          }
          lead={t({
            en: <>Edit your clip or add information.</>,
            fr: <>Modifiez votre clip.</>,
          })}
        />
        {/* {false && withCfc && (
          <>
            <DrawerSeparator>
              <div className="rr-t2">{t({ en: "Source CFC" })}</div>
            </DrawerSeparator>
            <div className="my-4">
              <div className="form-group">
                <div className="input-group">
                  <Select
                    isClearable
                    className="flex-grow-1"
                    isDisabled={loading}
                    placeholder={t({ en: "Select...", fr: "Choisissez..." })}
                    loadOptions={(q) =>
                      api
                        .SearchSources({
                          search: q,
                          kinds: "" as any,
                          // kinds: [], // "print", "audiovisual", "online"],
                          offset: 0,
                          limit: 100,
                        })
                        .then((res) => res.data)
                        .then((res) =>
                          res.map((s) => ({
                            value: s,
                            label: sourceLabel(s),
                          })),
                        )
                    }
                    defaultValue={
                      page.source
                        ? {
                            value: page.source?.id,
                            label: sourceLabel(page.source!),
                          }
                        : null
                    }
                    onChange={(value) =>
                      setSourceId((value?.value as any)?.id || null)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )} */}
        <DrawerSeparator>
          <div className="d-flex justify-content-between align-items-center">
            <div className="rr-t2 my-0 py-0">
              {t({ en: "General", fr: "Général" })}
            </div>
            <div className="">
              <select
                className="px-1 bg-primary text-white rounded"
                value={kind}
                onChange={(e) => setKind(e.target.value as Page["kind"])}
              >
                <option value="custom">
                  {t({ en: "Other", fr: "Autre" })}
                </option>
                <option value="press">
                  {t({ en: "Print", fr: "Presse Écrite" })}
                </option>
                <option value="event">
                  {t({ en: "Event", fr: "Événement" })}
                </option>
                <option value="radio">{t({ en: "Radio", fr: "Radio" })}</option>
                <option value="tv">{t({ en: "TV", fr: "TV" })}</option>
                <option value="podcast">
                  {t({ en: "Podcast", fr: "Podcast" })}
                </option>
                <option value="youtube">
                  {t({ en: "YouTube", fr: "YouTube" })}
                </option>
                <option value="web">{t({ en: "Web", fr: "Site web" })}</option>
                <optgroup label={t({ en: "Social", fr: "Social" })}>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="linkedin">LinkedIn</option>
                  <option value="pinterest">Pinterest</option>
                  <option value="tiktok">TikTok</option>
                  <option value="twitter">Twitter/X</option>
                </optgroup>
              </select>
            </div>
          </div>
        </DrawerSeparator>
        <div className="my-4">
          {page.url && (
            <div className="form-group">
              <label className="mb-0">{t({ en: "Link:", fr: "Lien:" })}</label>
              <div className="nowrap-ellipsis">
                <a
                  href={page.url}
                  target="_blank"
                  rel="noreferrer"
                  className="rr-t5"
                >
                  {page.url}
                </a>
              </div>
            </div>
          )}
          {page.logo_url && (
            <div className="form-group">
              <label>Logo:</label>
              <div>
                <img
                  alt="Logo"
                  src={page.logo_url}
                  style={{ maxWidth: "160px" }}
                />
              </div>
            </div>
          )}
          {page.kind === "custom" ? (
            <div className="form-group">
              <label>
                {t({ en: "Clip image(s):", fr: "Image(s) coupure(s) :" })}
              </label>
              <div className="d-flex align-items-center overflow-x-auto">
                <MultiImages
                  images={screenshots}
                  setImages={setScreenshots}
                  setMainImage={setImage}
                  maxLength={3}
                />
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label>{t({ en: "Clip image:", fr: "Image coupure :" })}</label>
              <div className="d-flex align-items-center overflow-x-auto">
                {image ? (
                  <UploadImage
                    className="rounded border"
                    url={image}
                    width={"160px"}
                    height={"120px"}
                    id="img-upload"
                    onUploaded={setImage}
                  />
                ) : (
                  <UploadBlock
                    id="img-upload"
                    className="d-block"
                    onUploaded={setImage}
                  >
                    <div
                      className="d-flex align-items-center justify-content-center rr-grey btn btn-secondary cursor-pointer p-0"
                      style={{
                        width: "160px",
                        height: "120px",
                        borderRadius: "6px",
                        backgroundColor: theme.lighterGrey,
                        border: `1px solid ${theme.lightGrey}`,
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </div>
                  </UploadBlock>
                )}
                {getPageScreenshots(page).map((src, i) => (
                  <div className="ml-3 text-center">
                    <label className="rr-t5 rr-grey mb-0">Alt. {i + 1}</label>
                    <div className="text-center">
                      <img
                        key={src}
                        src={addVersion(page, src)}
                        alt=""
                        className="cursor-pointer rounded border"
                        width="140"
                        height="90"
                        onClick={() => setImage(src)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="form-group">
            <label>
              {t({ en: "Publication date:", fr: "Date de publication :" })}
            </label>
            <div>
              <DatePicker
                onChange={setDate}
                selected={date}
                onBlur={() => {}}
                className="form-control d-block w-100"
                dateFormat={t({ en: "P", fr: "dd/MM/yyyy" })}
                placeholderText="No publication date added"
              />
            </div>
          </div>
          <div className="form-group">
            <label>{t({ en: "Publication name:", fr: "Publication :" })}</label>
            <SourceInput
              initialSource={page.source}
              setSource={(v) => {
                if (v === null) {
                  setSource(null);
                  setSourceId(null);
                } else {
                  setSource(v);
                  if ("id" in v) {
                    setCfcSource(v.cfc);
                    setSourceId(v.id);
                    let changes: any[] = [];
                    if (v.readership > 0) {
                      changes.push(["offline_users", false, v.readership]);
                    }
                    if (v.views > 0) {
                      changes.push(["offline_views", false, v.views]);
                    }
                    if (changes.length > 0) {
                      handleStatChange(changes);
                    }
                  } else {
                    setSourceId(null);
                  }
                }
              }}
            />
            {withCfc && source && (
              <div className="custom-control custom-switch d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="switchCfcEnabled"
                  checked={!!cfcSource}
                  onChange={(e) => setCfcSource(e.target.checked)}
                />
                <label
                  className="custom-control-label rr-t3 rr-mid-grey pt-1"
                  htmlFor="switchCfcEnabled"
                >
                  {t({
                    en: "CFC Source",
                    fr: "Source CFC",
                  })}
                </label>
              </div>
            )}
          </div>
          <div className="form-group">
            <label>{t({ en: "Clip title:", fr: "Titre :" })}</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>{t({ en: "Description:", fr: "Description :" })}</label>
            <div
              className="mde-sm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SimpleMDE
                options={{
                  spellChecker:
                    navigator.language.substring(0, 2).toLowerCase() === "en",
                  maxHeight: "100px",
                  inputStyle: "contenteditable",
                }}
                value={desc}
                onChange={(v) => setDesc(v.trim())}
              />
            </div>
          </div>
        </div>
        <DrawerSeparator>
          <div className="d-flex justify-content-between align-items-center">
            <div className="rr-t2">{t({ en: "Data", fr: "Données" })}</div>
            {page.url &&
              !["custom", "import", "press", "radio", "tv", "podcast"].includes(
                page.kind
              ) && (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleRefresh}
                >
                  {t({ en: "Refresh stats", fr: "Rafraichir les stats" })}
                </button>
              )}
          </div>
        </DrawerSeparator>
        <div className="my-4">
          <div>
            {displayedStats && (
              <>
                {displayedStats.map(({ group, stats }, i) => (
                  <StatSection key={i}>
                    <h6 className="rr-t3 bolder rr-dark-grey">
                      {t((texts.stats.groups as any)[group] || { en: group })}
                    </h6>
                    {stats.map((stat) => (
                      <Stat
                        id={stat.key}
                        key={stat.key}
                        label={t(
                          (texts.stats.labels as any)[stat.label] || {
                            en: stat.label,
                          }
                        )}
                        stat={
                          stat.value || {
                            key: stat.key,
                            udate: new Date().toISOString(),
                            disabled: true,
                            rrValue: undefined,
                            userValue: undefined,
                          }
                        }
                        onChange={(k, d, v) => handleStatChange([[k, d, v]])}
                      />
                    ))}
                  </StatSection>
                ))}
              </>
            )}
          </div>
        </div>
        <AdminContent page={page} />
      </DrawerBody>
      {/* Footer + delete buttons */}
      <DrawerFooter className="d-flex justify-content-around">
        <button
          className="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#delete-modal"
          aria-expanded="false"
          disabled={loading}
        >
          {t(appTexts.delete)}
        </button>
        {createPortal(
          <DeleteModal loading={loading} handleDelete={handleDelete} />,
          document.body
        )}
        {/* <button
          className="btn btn-secondary btn-sm"
          onClick={handleToggleVisibility}
        >
          {page.hidden
            ? t({ en: "Show", fr: "Montrer" })
            : t({ en: "Hide", fr: "Cacher" })}
        </button> */}
        <button
          className="btn btn-primary btn-sm"
          disabled={!hasModification || loading}
          onClick={handleSave}
        >
          {t(appTexts.save)}
        </button>
      </DrawerFooter>
    </>
  );
}

const StatSection = styled.div`
  margin-top: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${theme.lightGrey};
  :last-child {
    border-bottom: none;
  }
`;

/**
 * Displayed when the crawling failed for some reason.
 */
function ErrorContent({ page }: { page: Page }) {
  const t = useI18n();
  const close = useAppDrawer((s: DrawerState) => s.close);
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const [url, setUrl] = React.useState(page.url);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    load(
      dispatch(actions.pages.deletePage(page.id))
        .then(() =>
          dispatch(actions.pages.addPages(page.project_id, [url.trim()], true))
        )
        .then(close)
        .then(() => toasts.success("Retrying."))
    );
  };
  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    load(
      dispatch(actions.pages.deletePage(page.id))
        .then(close)
        .then(() => toasts.success("Clip deleted."))
    );
  };
  return (
    <>
      <DrawerBody>
        <DrawerTitle
          title={t({ en: <>Clip error</>, fr: <>Erreur de traitement</> })}
          lead={t({
            en: "Clipping failed",
            fr: "Erreur détectée lors de l'analyse",
          })}
        />
        {/* Pages imported from page cannot be retryied. */}
        {page.file_id ? (
          <>
            <DrawerSectionFrame
              title={t({ en: "What is this ?", fr: "Origine du problème :" })}
            >
              <DrawerSectionBody>
                <p className="mb-0">
                  {t({
                    en: (
                      <>
                        Our importer failed to process the provided file. Our
                        team will look into it!
                      </>
                    ),
                    fr: (
                      <>
                        Notre importeur n'a pas réussi à traiter le fichier.
                        Notre équipe prendra en compte le problème
                        prochainement.
                      </>
                    ),
                  })}
                </p>
                {page.url && (
                  <div
                    className="small font-weight-light mt-2"
                    style={{ wordWrap: "break-word" }}
                  >
                    {t({ en: "Your file:", fr: "Votre fichier :" })}
                    <a href={page.url} target="_blank" rel="noreferrer">
                      <Link height={16} /> {page.url}
                    </a>
                  </div>
                )}
              </DrawerSectionBody>
              <DrawerSection title="Retry">
                <form onSubmit={handleSubmit}>
                  <div>
                    <button type="submit" className="btn btn-primary btn-sm">
                      {t(appTexts.retry)}
                    </button>
                  </div>
                </form>
              </DrawerSection>
            </DrawerSectionFrame>
          </>
        ) : (
          <>
            <DrawerSectionFrame title="What is this ?">
              <DrawerSectionBody>
                <p className="mb-0">
                  {t({
                    en: (
                      <>
                        Don't worry it usually means that the URL is wrong,
                        please correct and try again.
                      </>
                    ),
                    fr: (
                      <>
                        Ne vous inquiétez pas, il s'agit généralement d'une
                        simple erreur dans le lien fourni, corrigez-le et
                        essayez à nouveau .
                      </>
                    ),
                  })}
                </p>
                {page.url && page.kind !== "custom" && (
                  <div
                    className="small font-weight-light mt-2"
                    style={{ wordWrap: "break-word" }}
                  >
                    {t({ en: "Your link:", fr: "Votre lien :" })}
                    <a href={page.url} target="_blank" rel="noreferrer">
                      <Link height={16} /> {page.url}
                    </a>
                  </div>
                )}
              </DrawerSectionBody>
              {page.url && page.kind !== "custom" && (
                <DrawerSection title="Retry">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>
                        {t({ en: "Correct the URL:", fr: "Corrigez l'URL :" })}
                      </label>
                      <input
                        type="url"
                        className="form-control is-invalid"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary btn-sm">
                        {t(appTexts.retry)}
                      </button>
                    </div>
                  </form>
                </DrawerSection>
              )}
            </DrawerSectionFrame>
          </>
        )}
        <AdminContent page={page} />
      </DrawerBody>
      <DrawerFooter className="text-center">
        <button
          className="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#delete-modal"
          aria-expanded="false"
          disabled={loading}
        >
          {t(appTexts.delete)}
        </button>
        {createPortal(
          <DeleteModal loading={loading} handleDelete={handleDelete} />,
          document.body
        )}
      </DrawerFooter>
    </>
  );
}

/**
 * A confirmation modal for page deletion.
 */
function DeleteModal({ handleDelete, loading }: any) {
  const t = useI18n();
  return (
    <div
      id="delete-modal"
      className="modal fade"
      data-keyboard="false"
      tabIndex={-1}
      data-dismiss="modal"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-4">
            <h2 className="pb-4">
              {t({ en: "Delete clip", fr: "Supprimer la coupure" })}
            </h2>
            <p className="pb-4">
              {t({
                en: (
                  <>
                    Are you sure you want to delete selected items? This action
                    can not be undone.
                  </>
                ),
                fr: (
                  <>
                    Souhaitez-vous réellement supprimer cette coupure ? Cette
                    action est irréversible.
                  </>
                ),
              })}
            </p>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-secondary"
                data-dismiss="modal"
                disabled={loading}
              >
                {t(appTexts.cancel)}
              </button>
              <button
                className="btn btn-danger"
                onClick={handleDelete}
                disabled={loading}
              >
                {t(appTexts.delete)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Displayed white the clip is crawling.
 */
function CrawlingContent({ page }: { page: Page }) {
  const t = useI18n();
  return (
    <DrawerBody>
      <DrawerTitle
        title={t({
          en: <>We are working on your clip</>,
          fr: <>Nous travaillons sur votre coupure</>,
        })}
        lead={t({
          en: (
            <>
              Reach Report is 📸 taking screenshots and 📈 gathering stats and
              meta data about your clip, please wait...
            </>
          ),
          fr: (
            <>
              Reach Report prend 📸 des captures d'écrans et 📈 récupère des
              stats ainsi que des meta-données à partir de votre lien, merci de
              patienter...
            </>
          ),
        })}
      />
      <AdminContent page={page} />
    </DrawerBody>
  );
}

/**
 * Some data usefull to Admins.
 */
function AdminContent({ page }: { page: Page }) {
  const session = useAppState((s) => s.session);
  if (!session?.admin) return null;
  return (
    <>
      <DrawerSeparator>Admin</DrawerSeparator>
      <div className="my-4">
        <div className="form-group">
          <label>ID</label>
          <input
            onFocus={(e) => {
              const self = e.target;
              setTimeout(() => {
                self.select();
                document.execCommand("copy");
              }, 0);
            }}
            className="form-control form-control-sm"
            value={page.id}
          />
        </div>
      </div>
    </>
  );
}

/**
 * Let the user upload up to maxLength images.
 */
function MultiImages({
  images,
  setImages,
  setMainImage,
  maxLength,
}: {
  images: string[];
  setImages: (images: string[]) => void;
  setMainImage: (image: string) => void;
  maxLength: number;
}) {
  const handleDelete = (i: number) => {
    const newImages = images.filter((_, j) => j !== i);
    setImages(newImages);
    setMainImage(newImages[0] || "");
  };
  const onUploaded = (url: string, i: number) => {
    const newImages = images.concat(url);
    setImages(newImages);
    setMainImage(newImages[0] || "");
  };
  return (
    <div className="d-flex align-items-center mb-2">
      {images.map((img, i) => (
        <div key={i} className="m-0 mr-2 p-0">
          <UploadImage
            url={img}
            onUploaded={(url) =>
              url === null ? handleDelete(i) : onUploaded(url, i)
            }
            id={`img-${i}`}
            width={"160px"}
            height={"120px"}
            className="mt-0 rounded border"
            canDelete
          />
        </div>
      ))}
      {images.length < maxLength && (
        <UploadBlock
          id={`img-${images.length}`}
          className="m-0 mb-0 p-0"
          onUploaded={(url) => onUploaded(url, images.length)}
        >
          <div
            className="d-flex align-items-center justify-content-center rr-grey btn btn-secondary cursor-pointer p-0"
            style={{
              width: "160px",
              height: "120px",
              borderRadius: "6px",
              backgroundColor: theme.lighterGrey,
              border: `1px solid ${theme.lightGrey}`,
              overflow: "hidden",
            }}
          >
            <Image
              style={{
                height: "20px",
                width: "20px",
              }}
            />
          </div>
        </UploadBlock>
      )}
    </div>
  );
}
